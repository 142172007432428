const ENV = {
  dev: {
    apiUrl: 'https://localhost:44303',
    oAuthConfig: {
      issuer: 'https://localhost:44303',
      clientId: 'TLPayment_App',
      clientSecret: '1q2w3e*',
      scope: 'offline_access TLPayment',
    },
    localization: {
      defaultResourceName: 'TLPayment',
    },
  },
  prod: {
    apiUrl: 'https://host.payment.frameinsure.com',
    oAuthConfig: {
      issuer: 'https://host.payment.frameinsure.com',
      clientId: 'TLPayment_App',
      clientSecret: '1q2w3e*',
      scope: 'offline_access TLPayment',
    },
    localization: {
      defaultResourceName: 'TLPayment',
    },
  },
};


export const getEnvVars = () => {
  // eslint-disable-next-line no-undef
  return __DEV__ ? ENV.dev : ENV.prod;
};