import React from 'react';
import HomeStackNavigator from './HomeNavigator';

import { createStackNavigator } from '@react-navigation/stack';
import { Icon, TopNavigationAction } from '@ui-kitten/components';

import AppActions from '../store/actions/AppActions';
import { connectToRedux } from '../utils/ReduxConnect';
import PropTypes from 'prop-types';
import { createAction } from '@reduxjs/toolkit';

const { Navigator, Screen } = createStackNavigator();
const Stack = createStackNavigator();

// const DrawerContent = ({ navigation, state }) => (
//   <Drawer
//     selectedIndex={new IndexPath(state.index)}
//     onSelect={index => navigation.navigate(state.routeNames[index.row])}>
//     <DrawerItem title='Home' />
//     <DrawerItem title='Login' />
//     <DrawerItem title='Register' />
//     <DrawerItem title='BindCard' />
//     <DrawerItem title='SignAgreement' />
//     <DrawerItem title='SignAuthAgreement' />
//   </Drawer>
// );
const BackIcon = props => <Icon {...props} name="arrow-back" />;
const BackAction = () => <TopNavigationAction icon={BackIcon} onPress={navigateBack} />;

export const DrawerNavigator = () => (
  // <Navigator>
  // <Stack.Navigator initialRouteName="Home">
  //   <Stack.Screen
  //     name="Home"
  //     component={HomeScreen}
  //     options={({ navigation }) => ({
  //       //headerLeft: () => <MenuIcon onPress={() => navigation.openDrawer()} />,
  //       title: '通联电子账户',
  //     })}
  //   />
  //   <Stack.Screen name="Login" component={LoginScreen} options={({ navigation }) => ({
  //       headerLeft: null,
  //       title: '通联电子账户',
  //     })}/>
  //   <Stack.Screen
  //     name="Register"
  //     component={RegisterScreen}
  //     options={({ navigation }) => ({
  //       headerLeft:null,
  //       // headerLeft: () => (
  //       //   <TopNavigationAction
  //       //     icon={BackIcon}
  //       //     onPress={() => {
  //       //       navigation.goBack();
  //       //     }}
  //       //   />
  //       // ),
  //        title: '注册通联电子账户',
  //     })}
  //   />
  //   <Stack.Screen
  //     name="BindCard"
  //     component={BindCardScreen}
  //     options={({ navigation }) => ({ title: '注册通联电子账户',  headerLeft: null })}
  //   />
  //   <Stack.Screen
  //     name="SignAgreement"
  //     component={SignAgreementScreen}
  //     options={({ navigation }) => ({ title: '注册通联电子账户',  headerLeft: null })}
  //   />
  //   <Stack.Screen
  //     name="SignAgreementResult"
  //     component={SignAgreementResultScreen}
  //     options={({ navigation }) => ({ title: '注册通联电子账户',  headerLeft: null })}
  //   />
  //   <Stack.Screen
  //     name="SignAuthAgreement"
  //     component={SignAuthAgreementScreen}
  //     options={({ navigation }) => ({ title: '注册通联电子账户',  headerLeft: null })}
  //   />
  //   <Stack.Screen
  //     name="SignAuthResult"
  //     component={SignAuthResultScreen}
  //     options={({ navigation }) => ({ title: '注册通联电子账户' ,  headerLeft: null})}
  //   />
  // </Stack.Navigator>
  // {/* <Screen name='Home' component={HomeStackNavigator}/> */}
  // {/* <Screen name='Login' component={AuthStackNavigator}/>
  // <Screen name='Register' component={RegisterStackNavigator}/> */}
  //  {/* <Screen name='BindCard' component={BindCardStackNavigator}/> */}
  // {/* <Screen name='SignAgreement' component={SignAgreementStackNavigator}/>
  // <Screen name='SignAuthAgreement' component={SignAuthAgreementStackNavigator}/> */}
  // </Navigator>
  <Navigator>
    <Screen name='Home' component={HomeStackNavigator} 
    options={() => ({
          title: '通联电子账户',
          headerRight:null,
        })}/>
  </Navigator>
);


