import React, { useEffect, useState } from 'react';
import { SafeAreaView, StyleSheet, View,Linking } from 'react-native';
import {
  Button,
  Divider,
  Layout,
  Text,
  TopNavigation,
  Card,
  Toggle,
  Spinner,
} from '@ui-kitten/components';
import { getIndividualByIdentityNumber, postCheck, postCreatePay,getAccountInfo } from '../api/TLCustAPI';
import { getProfileDetail } from '../api/IdentityAPI';
import PropTypes from 'prop-types';
import AppActions from '../store/actions/AppActions';
import { connectToRedux } from '../utils/ReduxConnect';
import { save, getString, saveObject, getObject } from '../utils/AppStorage';
import Loading from '../components/Loading/Loading';

function IndexScreen({ navigation, logoutAsync, route }) {
  const [registerStep, setRegisterStep] = useState();

  const [identityNumber = '', setIdentityNumber] = useState();
  const [mobile = '', setMobile] = useState();
  const [name = '', setName] = useState();
  const [acctStatus = '否', setAcctStatus] = useState();
  const [dataLoading = false, setDataLoading] = useState();
  const [syUrl = '', setSyUrl] = useState();
  const [jqUrl = '', setJqUrl] = useState();
  const [hadProposal = false, setHadProposal] = useState();
  const [balance = '0', setBalance] = useState();

  useEffect(() => {
    setDataLoading(true);
    getObject('loginInfo').then(loginData => {
      getProfileDetail()
        .then((profileData = {}) => {
          //加载数据检测注册情况
          postCheck({
            mobile: profileData.phoneNumber,
            name: profileData.name,
            identityNumber: profileData.surname,
          })
            .then(data => {
              var step = -1;
              if (data && data.code == '000000') {
                step = data.data;
                setRegisterStep(data.data);
              } else {
                step = 0;
                setRegisterStep(0);
              }
              var idd = '';
              if (profileData.surname.length == 15) {
                idd =
                  profileData.surname.substring(0, 6) +
                  '******' +
                  profileData.surname.substring(11, 15);
              } else if (profileData.surname.length == 18) {
                idd =
                  profileData.surname.substring(0, 6) +
                  '******' +
                  profileData.surname.substring(14, 18);
              }
              setIdentityNumber(idd);
              setName(profileData.name);

              var mm =
                profileData.phoneNumber.substring(0, 3) +
                '****' +
                profileData.phoneNumber.substring(7, 11);
              setMobile(mm);

              navigateDetails(profileData.name, profileData.surname, profileData.phoneNumber, step);

              if (step==100){
                getAccountInfo( {identityNumber: profileData.surname}).then((data)=>{
                    var info = data.data;
                    if (info){
                      var jq=info.jqProposalUrl;
                      var sy = info.syProposalUrl;

                      if(jq || sy){
                        setJqUrl(jq);
                        setSyUrl(sy);
                        if(jq=='' && sy==''){
                          setHadProposal(true);
                        }else{
                          setHadProposal(false);
                        }

                        if(info.Balance){
                          setBalance(info.balance);
                        }
                      }else{
                        setHadProposal(true);
                      }
                    }else{
                      setHadProposal(true);
                    }
                   
                }).catch(e => { });
              }
            })
            .catch(e => {})
            .finally(() => setDataLoading(false));
        })
        .catch(e => {
          setDataLoading(false);
          logoutAsync();
        })
        .finally(() => {});
    });
  }, []);

  const navigateDetails = (name, identityNumber, mobile, step) => {
    var navigateName = '';
    switch (step) {
      case 0:
        navigateName = 'Register';
        break;
      case 1:
        navigateName = 'BindCard';
        break;
      case 2:
        navigateName = 'SignAgreement';
        break;
      case 3:
        navigateName = 'SignAgreement';
        break;
      case 4:
        navigateName = 'SignAuthAgreement';
        break;
      case 5:
      case 100:
        //toast.show('电子账户注册完成', { type: 'success' });
        setAcctStatus('是');

        // getString('orderId').then(data=>{
        //   var orderId = data;
        //   postCreatePay({ identityNumber: identityNumber,orderId: orderId});
        // });
        break;
      default:
    }

    if (navigateName != '') {
      navigation.navigate(navigateName, {
        identityNumber: identityNumber,
        name: name,
        mobile: mobile,
      });
    }
  };

  const Header = props => (
    <View {...props}>
      <Text category="s1">基本信息</Text>
    </View>
  );

  const PayHeader = props => (
    <View {...props}>
      <Text category="s1">分期付款信息</Text>
    </View>
  );

  const ProposalHeader = props => (
    <View {...props}>
      <Text category="s1">保单信息</Text>
    </View>
  );


  const SyOpen=()=>{
      Linking.canOpenURL(syUrl).then(canOpen=>{
        if(canOpen){
          Linking.openURL(syUrl);
        }
    });
  };

  const JqOpen=()=>{
    Linking.canOpenURL(jqUrl).then(canOpen=>{
      if(canOpen){
        Linking.openURL(jqUrl);
      }
  });
};
  
  return (
    <SafeAreaView style={{ flex: 1 }}>
      <Layout style={{ flex: 1, justifyContent: 'space-evenly' }}>
        <Card
          header={Header}
          style={styles.card1}
          status="success">
          <View style={styles.detail}>
            <Text>姓名：</Text>
            <Text>{name}</Text>
          </View>
          <View style={styles.detail}>
            <Text>手机号：</Text>
            <Text>{mobile}</Text>
          </View>
          <View style={styles.detail}>
            <Text>身份证号：</Text>
            <Text>{identityNumber}</Text>
          </View>
          <View style={styles.detail}>
            <Text>是否开通电子账户：</Text>
            <Text status="info">{acctStatus}</Text>
          </View>
          <View style={styles.detail}>
            <Text>电子账户余额：</Text>
            <Text>{balance}</Text>
          </View>
        </Card>
        <Card
          style={styles.card1}
          header={ProposalHeader}
          status="warning">
          <View style={styles.horizontal}>
            {hadProposal && <Text>无保单信息</Text>}
           
            { jqUrl &&   <Button size='small' status='info' onPress={JqOpen}>
              交强险保单
              </Button>}
                           
              { syUrl &&   
                        <Button size='small' status='info' onPress={SyOpen}>
                        商业险保单
                        </Button>
             }
          
          </View>
        
        </Card>
        <Card
          style={styles.card1}
          header={PayHeader}
          status="info"
          onPress={() => {
            toast.show('分期明细功能在处理中', { type: 'success' });
          }}>
          <View style={styles.detail}>
            <Text>分期期数：</Text>
            <Text>{}</Text>
          </View>
          <View style={styles.detail}>
            <Text>当前期数：</Text>
            <Text></Text>
          </View>
          <View style={styles.detail}>
            <Text>代扣协议有效期：</Text>
            <Text>{''}</Text>
          </View>
          <View style={styles.detail}>
            <Text>服务内容：</Text>
            <Text>{''}</Text>
          </View>
        </Card>
        {/* <Card
          style={styles.card}
          status="warning"
          onPress={() => {
            toast.show('电子账户注册完成', { type: 'success' });
          }}>
          <Text>我的扣款记录</Text>
        </Card> */}
        <View style={styles.detail1}>
          <Button
            style={styles.button}
            appearance="outline"
            status="info"
            onPress={() => {
              logoutAsync();
            }}>
            退出登录
          </Button>
        </View>
        {/* <Button onPress={navigateDetails} size={'small'}>OPEN DETAILS</Button> */}
      </Layout>
      <Loading loading={dataLoading}></Loading>
    </SafeAreaView>
  );
}

IndexScreen.propTypes = {
  logoutAsync: PropTypes.func.isRequired,
};

export default connectToRedux({
  component: IndexScreen,
  dispatchProps: {
    logoutAsync: AppActions.logoutAsync,
  },
});

const styles = StyleSheet.create({
  topContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  card: {
    margin: 12,
  },
  button: {
    margin: 12,
    width: 200,
  },
  card1: {
    margin: 12,
    marginBottom: 0,
  },
  footerContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  footerControl: {
    marginHorizontal: 2,
  },

  detail: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },

  detail1: {
    flexDirection: 'row',
    justifyContent:'center'
  },

  horizontal: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});
