import AsyncStorage from '@react-native-async-storage/async-storage';

export async function getString(key) {
  return await AsyncStorage.getItem(key);
}

export async function getObject(key) {
  return await AsyncStorage.getItem(key).then(jsonValue => {
    return jsonValue != null ? JSON.parse(jsonValue) : null;
  });
}

export async function save(key, value) {
  return await AsyncStorage.setItem(key, value);
}
export async function saveObject(key, value) {
  return await AsyncStorage.setItem(key, JSON.stringify(value));
}
export function update(key, value) {
  return AppStorage.get(key).then(item => {
    value = typeof value === 'string' ? value : Object.assign({}, item, value);
    return AsyncStorage.setItem(key, JSON.stringify(value));
  });
}
export function deleteKey(key) {
  return AsyncStorage.removeItem(key);
}

export function clear() {
  return AsyncStorage.clear();
}
