import React from 'react';
import { Spinner } from '@ui-kitten/components'
import { StyleSheet,View } from 'react-native';
import PropTypes from 'prop-types';
import { connectToRedux } from '../../utils/ReduxConnect';


export function Loading({  loading }) {
  return loading ? (
    <View style={styles.container}>
      <View
        style={styles.backdrop}
      />
      <Spinner />
    </View>
  ) : null;
}

const backdropStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: '#fff',
};

export const styles = StyleSheet.create({
  container: {
    ...backdropStyle,
    backgroundColor: 'transparent',
    zIndex:'1000',
    alignItems: 'center',
    justifyContent: 'center',
  },
  backdrop: { 
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'whitesmoke',
  opacity:0.7
},

});

Loading.propTypes = {
  loading: PropTypes.bool,
};

export default connectToRedux({
  component: Loading,
});
