import React, { useState } from 'react';
import { SafeAreaView, View, StyleSheet ,Linking} from 'react-native';
import {
  Button,
  ButtonGroup,
  Divider,
  Icon,
  Input,
  Layout,
  Text,
  TopNavigation,
  TopNavigationAction,
} from '@ui-kitten/components';
import * as Yup from 'yup';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import AppActions from '../../store/actions/AppActions';
import { connectToRedux } from '../../utils/ReduxConnect';
import {postSignAuthAgreement} from '../../api/TLCustAPI';



const BackIcon = props => <Icon {...props} name="arrow-back" />;

const ValidationSchema = Yup.object().shape({
  username: Yup.string().required('AbpAccount::ThisFieldIsRequired.'),
  password: Yup.string().required('AbpAccount::ThisFieldIsRequired.'),
});

function SignAuthAgreementScreen({ navigation,route }) {
  const navigateBack = () => {
    navigation.goBack();
  };
  const [message = '', setMessage] = useState();

  const submit = () => {
    var idNumber = route.params.identityNumber;

    var reqData = {
      jumpUrl: 'https://payment.insuramp.frameinsure.com/SignAuthResult',
      serverNotifyUrl: 'https://host.payment.frameinsure.com/SignAuthAgreementResult',
      identityNumber: idNumber,
    };

    postSignAuthAgreement(reqData)
    .then((data)=>{
      if(data && data.code == '000000'){
      
        var url = data.data.urlH5;
        var agreementNo =data.data.agreementNo;
        Linking.canOpenURL(url).then(canOpen=>{
            if(canOpen){
              Linking.openURL(url);
            }else{
            }
        });
      }else{
        toast.show(data.message);
        setMessage(data.message);
        if(data.message =='已签订扣款授权协议'){
          navigation.navigate('Index',{ identityNumber: idNumber });
        }
      }
    });
  };


  return (
    <SafeAreaView style={{ flex: 1 }}>
      {/* <TopNavigation title="签订扣款协议" alignment="center" />
      <Divider /> */}
      <Layout style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        <View>
          <View style={{ marginTop: 20 }}>
            <Button abpButton onPress={submit}>
              <Text style={{ color: 'white' }}>查看协议</Text>
            </Button>
          </View>
          <View style={{minHeight:100,maxWidth:280,textAlign:'center'}}>
            <Text style={{marginTop:20,flexWrap:'wrap'}} status='danger' category='p1'>{message}</Text>
          </View>
        </View>
      </Layout>
    </SafeAreaView>
  );
}

export default connectToRedux({
  component: SignAuthAgreementScreen,
  dispatchProps: {
    logoutAsync: AppActions.logoutAsync,
  },
});

const styles = StyleSheet.create({
  captionContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  captionIcon: {
    width: 10,
    height: 10,
    marginRight: 5,
  },
  captionText: {
    fontSize: 12,
    fontWeight: '400',
    fontFamily: 'opensans-regular',
    color: '#8F9BB3',
  },
  marginText: {
    marginBottom: 6,
  },
});
