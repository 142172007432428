import React, { useEffect } from 'react';
import * as eva from '@eva-design/eva';
import { AppRegistry } from 'react-native';
import { ApplicationProvider, IconRegistry } from '@ui-kitten/components';
import { EvaIconsPack } from '@ui-kitten/eva-icons';
import { AppNavigator } from './src/navigators/MyNavigation';
import { enableScreens } from 'react-native-screens';
import { initAPIInterceptor } from './src/interceptors/APIInterceptor';
import { persistor, store } from './src/store';
import AppContainer from './src/components/AppContainer/AppContainer';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';


enableScreens();
initAPIInterceptor(store);

export default function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <IconRegistry icons={EvaIconsPack} />
        <ApplicationProvider {...eva} theme={eva.light}>
          <AppContainer />
        </ApplicationProvider>
        {/* <Toast ref={(ref) => global['toast'] = ref} /> */}
      </PersistGate>
    </Provider>
  );
}

// export default function App() {
//   //const [isReady, setIsReady] = useState(false);

//   // useEffect(() => {

//   // }, []);

//   return (
//     <Provider store={store} {...eva} theme={eva.light}>
//       <PersistGate persistor={persistor}>
//       <IconRegistry icons={EvaIconsPack}/>
//            <AppNavigator />
//       </PersistGate>
//     </Provider>
//      );
// }
