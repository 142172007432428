import React, { useState, useEffect } from 'react';
import { SafeAreaView, View, StyleSheet } from 'react-native';
import {
  Button,
  Modal,
  Card,
  Divider,
  Icon,
  Input,
  Layout,
  Text,
  TopNavigation,
  TopNavigationAction,
} from '@ui-kitten/components';
import * as Yup from 'yup';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import AppActions from '../../store/actions/AppActions';
import { connectToRedux } from '../../utils/ReduxConnect';
import { postConfirmBindBankCard, postApplyBindCard } from '../../api/TLCustAPI';

import { NavigationContainer } from '@react-navigation/native';
const BackIcon = props => <Icon {...props} name="arrow-back" />;

const ValidationSchema = Yup.object().shape({
  username: Yup.string().required('AbpAccount::ThisFieldIsRequired.'),
  password: Yup.string().required('AbpAccount::ThisFieldIsRequired.'),
});

function BindCardScreen({ navigation, route }) {
  const navigateBack = () => {
    navigation.goBack();
  };

  const initialValues = {
    identityNumber: '320202198909284522',
  };

  const [smsCode = '', setSmsCode] = useState();
  const [smsSeqNo = '', setSmsSeqNo] = useState();
  const [bankCardMobile = '', setBankCardMobile] = useState();
  const [bankCardNo = '', setBankCardNo] = useState();

  const [message = '', setMessage] = useState();
  const [btnDisabled = true, setBtnDisabled] = useState();

  const AlertIcon = props => <Icon {...props} name="alert-circle-outline" />;

  useEffect(() => {
    //var idNumber = route.params.identityNumber;
    //setBankCardNo(idNumber);
  }, []);

  const submit = () => {
    setMessage('');
    // console.log(111);
    //console.log(initialValues.identityNumber);
    // startLoading({ key: 'login' });
    // login({ username, password })
    //   .then(data => {
    //     console.log(data);
    //   })
    //   .then(
    //     () =>
    //       new Promise(resolve =>
    //         fetchAppConfig({ showLoading: false, callback: () => resolve(true) }),
    //       ),
    //   )
    //   .catch(e => {
    //     //toast.show('登录失败', { type: 'error' });
    //     console.log(e);
    //   })
    //   .finally(() =>
    //     //    stopLoading({ key: 'login' })
    //     console.log(1),
    //   );
    var idNumber = route.params.identityNumber;

    var reqData = {
      bankCardMobile: bankCardMobile,
      bankCardNumber: bankCardNo,
      identityCardNumber: idNumber,
      smsCode: smsCode,
      smsSeqNo: smsSeqNo,
    };

    postConfirmBindBankCard(reqData).then(data => {
      if (data && data.code == '000000') {
        toast.show('已成功绑卡', { type: 'success' });
        navigation.navigate('SignAgreement', { identityNumber: route.params.identityNumber });
      } else {
        toast.show(data.message, { type: 'error' });
        setMessage(data.message);
      }
    });
  };

  const renderCaption = () => {
    return (
      <View style={styles.captionContainer}>
        {AlertIcon(styles.captionIcon)}
        <Text style={styles.captionText}></Text>
      </View>
    );
  };

  const handleSmsCode = () => {
    setMessage('');
    var idCard = route.params.identityNumber;
    toast.show('已发送短信验证码!');
    postApplyBindCard({
      bankCardNumber: bankCardNo,
      bankCardMobile: bankCardMobile,
      identityCardNumber: idCard,
    }).then(data => {
      if (data && data.code == '000000') {
        setSmsSeqNo(data.data.smsSeqNo);
        setBtnDisabled(false);
      } else {
        setMessage(data.message);
      }
    });
  };
  const onChangeSmsCode = values => {
    // do what you want like on submit
    setSmsCode(values);
  };

  return (
    <SafeAreaView style={{ flex: 1 }}>
      {/* <TopNavigation title="绑定银行卡" alignment="center" />
      <Divider /> */}
      <Layout style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        <View>
          <View style={styles.marginText}>
            <Text style={styles.marginText}>银行卡号</Text>
            <Input
            placeholder='请输入银行卡卡号'
              value={bankCardNo}
              caption={renderCaption}
              onChangeText={v => {
                setBankCardNo(v);
              }}
            />
          </View>
          <View style={styles.marginText}>
            <Text style={styles.marginText}>银行预留手机号</Text>
            <Input
             placeholder='请输入银行预留手机号'
              value={bankCardMobile}
              caption={renderCaption}
              onChangeText={v => {
                setBankCardMobile(v);
              }}
            />
          </View>
          <View style={styles.marginText}>
            <Text>验证码</Text>
            <View style={{ marginTop: 6, alignItems: 'center', flex: 1, flexDirection: 'row' }}>
              <Input value={smsCode} caption={renderCaption} onChangeText={onChangeSmsCode}  placeholder='请输入验证码'/>
              <Text
                onPress={handleSmsCode}
                style={{ color: 'blue', textDecorationLine: 'underline', marginLeft: 6 }}>
                {' '}
                获取验证码
              </Text>
            </View>
          </View>
          <View style={{ marginTop: 20 }}>
            <Button abpButton onPress={submit} disabled={btnDisabled}>
              <Text style={{ color: 'white' }}>申请绑卡</Text>
            </Button>
          </View>
          <View style={{minHeight:100,maxWidth:280,textAlign:'center'}}>
            <Text style={{marginTop:20,flexWrap:'wrap'}} status='danger' category='p1'>{message}</Text>
          </View>
        </View>
      </Layout>

    </SafeAreaView>
  );
}

export default connectToRedux({
  component: BindCardScreen,
  dispatchProps: {
    logoutAsync: AppActions.logoutAsync,
  },
});

const styles = StyleSheet.create({
  captionContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  captionIcon: {
    width: 10,
    height: 10,
    marginRight: 5,
  },
  captionText: {
    fontSize: 12,
    fontWeight: '400',
    fontFamily: 'opensans-regular',
    color: '#8F9BB3',
  },
  marginText: {
    marginBottom: 6,
  },

  modelContainer: {
    // display: 'flex',
    // flexDirection: 'row',
    // alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
    height: '200px',
    width: '240px',
    textAlign: 'center',
  },
  modelMarginText: {
    marginBottom: 20,
  },
  backdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
});
