import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import IndexScreen from '../screens/IndexScreen';
//import MenuIcon from '../components/MenuIcon/MenuIcon';
//import { LocalizationContext } from '../contexts/LocalizationContext';
import BindCardScreen from '../screens/TL/BindCardScreen';
import RegisterScreen from '../screens/TL/RegisterScreen';
import SignAgreementResultScreen from '../screens/TL/SignAgreementResultScreen';
import SignAgreementScreen from '../screens/TL/SignAgreementScreen';
import SignAuthAgreementScreen from '../screens/TL/SignAuthAgreementScreen';
import SignAuthResultScreen from '../screens/TL/SignAuthResultScreen';

const Stack = createStackNavigator();

export default function HomeStackNavigator() {
  //const { t } = React.useContext(LocalizationContext);


  return (
    <Stack.Navigator initialRouteName="主页" initial={false}>
      <Stack.Screen
        name="Index"
        component={IndexScreen}
        options={({ navigation,logoutAsync }) => ({
          headerLeft:  null,
          title: '我的账户',
          headerTitleAlign:'center',
          headerBackTitleVisible:false
        })}
      />
      <Stack.Screen
        name="Register"
        component={RegisterScreen}
        options={({ navigation }) => ({
          headerLeft: null,
          // headerLeft: () => (
          //   <TopNavigationAction
          //     icon={BackIcon}
          //     onPress={() => {
          //       navigation.goBack();
          //     }}
          //   />
          // ),
          headerTitleAlign:'center',
          title: '会员注册',
        })}
      />
      <Stack.Screen
        name="BindCard"
        component={BindCardScreen}
        options={({ navigation }) => ({ title: '绑定银行卡', headerTitleAlign:'center', headerLeft: null })}
      />
      <Stack.Screen
        name="SignAgreement"
        component={SignAgreementScreen}
        options={({ navigation }) => ({ title: '签订开户协议并开户', headerTitleAlign:'center', headerLeft: null })}
      />
      <Stack.Screen
        name="SignAgreementResult"
        component={SignAgreementResultScreen}
        options={({ navigation }) => ({ title: '开户协议结果', headerTitleAlign:'center', headerLeft: null })}
      />
      <Stack.Screen
        name="SignAuthAgreement"
        component={SignAuthAgreementScreen}
        options={({ navigation }) => ({ title: '签订扣款协议', headerTitleAlign:'center', headerLeft: null })}
      />
      <Stack.Screen
        name="SignAuthResult"
        component={SignAuthResultScreen}
        options={({ navigation }) => ({ title: '签订扣款协议结果', headerTitleAlign:'center', headerLeft: null })}
      />
    </Stack.Navigator>
  );
}


