import React from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import  HomeScreen  from '../screens/IndexScreen';
// import { DetailsScreen } from '../screens/DetailScreen';
// import AuthNavigator from '../navigators/AuthNavigator';
import LoginScreen from '../screens/LoginScreen';
// import HomeStackNavigator from './HomeNavigator';
// import { createDrawerNavigator } from '@react-navigation/drawer';

const { Navigator, Screen } = createStackNavigator();
//const Drawer = createDrawerNavigator();

export default function HomeNavigator() {
  return (
    <Navigator headerMode="none">
      <Screen name="Home" component={HomeScreen} />
      <Screen name="Login" component={LoginScreen} />
    </Navigator>
  );
}

// export const AppNavigator = () => (
//    <NavigationContainer>
//     <HomeNavigator/>
//    </NavigationContainer>
// );

// export default function AppNavigator() {
//     return (
//       <Drawer.Navigator initialRouteName="Home">
//         <Drawer.Screen name="Home" component={HomeStackNavigator} />
//         {/* <Drawer.Screen name="Users" component={UsersStackNavigator} /> */}
//         <Drawer.Screen name="Login" component={AuthNavigator} />
//         {/* <Drawer.Screen name="Settings" component={SettingsStackNavigator} /> */}
//       </Drawer.Navigator>
//     );
//   }
