import React, { useState ,useEffect} from 'react';
import { SafeAreaView, View, StyleSheet,Linking  } from 'react-native';
import {
  Button,
  Modal,
  Divider,
  Icon,
  Card,
  Layout,
  Text,
  TopNavigation,
  TopNavigationAction,
} from '@ui-kitten/components';
import * as Yup from 'yup';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import AppActions from '../../store/actions/AppActions';
import { connectToRedux } from '../../utils/ReduxConnect';
import { postSms, postSignAgreementResult } from '../../api/TLCustAPI';

const BackIcon = props => <Icon {...props} name="arrow-back" />;

const ValidationSchema = Yup.object().shape({
  username: Yup.string().required('AbpAccount::ThisFieldIsRequired.'),
  password: Yup.string().required('AbpAccount::ThisFieldIsRequired.'),
});

function SignAgreementResultScreen({ navigation, route }) {
  const navigateBack = () => {
    navigation.goBack();
  };

  const [identityNumber='',setIdentityNumber]=useState();
  const [message = '', setMessage] = useState();
  const [successMsg = '', setSuccessMsg] = useState();

  useEffect(() => {
    if(route.params){
        var params = route.params;
        var agreementNo = params.agreementNo;
        var custId = params.custId;
        var signResult = params.signResult;
        var subMsg = params.subMsg;
        var subCode = params.subCode;
        if(signResult=='1' || (signResult=='2' && subMsg=='协议已签约')){
            setSuccessMsg('已完成开户');
            //保存签约数据
            var req ={
                custId:custId,
                agreementNo:agreementNo,
                signResult:signResult,
                subCode :subCode,
                subMsg:subMsg
            }
            postSignAgreementResult(req).then((data)=>{
                if (data && data.code == '000000') {
                    toast.show('已开户成功', { type: 'success' });
                    setIdentityNumber(data.data);
                  } else {
                    toast.show(data.message);
                    setMessage(data.message);
                  }
            });
        }else{
            //其他失败原因
            toast.show(subMsg);
        }
    }
  }, []);


  const submit = () => {
    navigation.navigate('SignAuthAgreement',{ identityNumber: identityNumber });
  };

  return (
    <SafeAreaView style={{ flex: 1 }}>
      {/* <TopNavigation title="开户协议结果" alignment="center"/>
      <Divider /> */}
      <Layout style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        <View>
        <Text>{successMsg}</Text>
          <View style={{ marginTop: 20 }}>
            <Button abpButton onPress={submit}>
              <Text style={{ color: 'white' }}>去签订扣款协议</Text>
            </Button>
          </View>
          <View style={{minHeight:100,maxWidth:280,textAlign:'center'}}>
            <Text style={{marginTop:20,flexWrap:'wrap'}} status='danger' category='p1'>{message}</Text>
          </View>
        </View>
      </Layout>
    </SafeAreaView>
  );
}

export default connectToRedux({
  component: SignAgreementResultScreen,
  dispatchProps: {
    logoutAsync: AppActions.logoutAsync,
  },
});

const styles = StyleSheet.create({
  captionContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  captionIcon: {
    width: 10,
    height: 10,
    marginRight: 5,
  },
  captionText: {
    fontSize: 12,
    fontWeight: '400',
    fontFamily: 'opensans-regular',
    color: '#8F9BB3',
  },
  marginText: {
    marginBottom: 6,
  },

  modelContainer: {
    // display: 'flex',
    // flexDirection: 'row',
    // alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
    height: '200px',
    width: '240px',
    textAlign: 'center',
  },
  modelMarginText: {
    marginBottom: 20,
  },
  backdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
});
