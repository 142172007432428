import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import { LocalizationContext } from '../contexts/LocalizationContext';
import LoginScreen from '../screens/LoginScreen';

// const { Navigator, Screen } = createStackNavigator();

// export default function AuthStackNavigator() {

//   return (
//     <Navigator headerMode="none">
//       <Screen name="Login" component={LoginScreen} />
//     </Navigator>
//   );
// }

const Stack = createStackNavigator();

export default function AuthStackNavigator() {
  //const { t } = React.useContext(LocalizationContext);

  return (
    <Stack.Navigator initialRouteName="Login">
      <Stack.Screen
        name="Login"
        component={LoginScreen}
        options={() => ({
          title: '登录',
          headerTitleAlign:'center'
        })}
      />
    </Stack.Navigator>
  );
}
