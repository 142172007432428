import { Formik } from 'formik';
import i18n from 'i18n-js';
import { Button, Input, Text, Icon, TopNavigation, Divider, Layout } from '@ui-kitten/components';
import PropTypes, { func } from 'prop-types';
import React, { useEffect, useState, useRef } from 'react';
import { TouchableWithoutFeedback, StyleSheet, View, SafeAreaView } from 'react-native';
import * as Yup from 'yup';
import { login } from '../api/AccountAPI';
import AppActions from '../store/actions/AppActions';
import LoadingActions from '../store/actions/LoadingActions';
import PersistentStorageActions from '../store/actions/PersistentStorageActions';
import { connectToRedux } from '../utils/ReduxConnect';
import { getIndividualByIdentityNumber } from '../api/TLCustAPI';
import { postTLCreate, postInsuranceOrder,postCreateInsuranceOrder } from '../api/TLCustAPI';
import { save, getString, saveObject, getObject } from '../utils/AppStorage';
import Loading from '../components/Loading/Loading';

const ValidationSchema = Yup.object().shape({
  username: Yup.string().required('AbpAccount::ThisFieldIsRequired.'),
  password: Yup.string().required('AbpAccount::ThisFieldIsRequired.'),
});

const BackIcon = props => <Icon {...props} name="arrow-back" />;

function LoginScreen({ navigation, setToken, fetchAppConfig, route }) {
  const [dataLoading = false, setDataLoading] = useState();
  const [value, setValue] = React.useState('');
  const initSecret = 'tl123456';

  useEffect(async () => {
    if (route && route.params) {
      var orderId = route.params.orderId;
      if (orderId) {
        save('orderId', orderId);

        setDataLoading(true);
        postInsuranceOrder({ orderId: orderId })
          .then(data => {
            var individualData = data.data;
            var phone = individualData.mobile;
            var idNumber = individualData.identityNumber;
            var name = individualData.name;

            if (phone && idNumber) {
              setDataLoading(true);

              login({ username: phone, password: initSecret })
                .then(data => {
                  var loginInfo = {
                    name: name,
                    phoneNumber: phone,
                    identityNumber: idNumber,
                  };

                  saveObject('loginInfo', loginInfo);

                  setToken({
                    ...data,
                    expire_time: new Date().valueOf() + data.expires_in,
                    scope: undefined,
                  });
                  var orderDto = {
                    identityNumber: idNumber,
                    name: name,
                    mobile: phone,
                    orderId: orderId,
                  };
                  postCreateInsuranceOrder(orderDto).then(data => { 
                   
                  });
                })
                .then(
                  () => new Promise(resolve => fetchAppConfig({ callback: () => resolve(true) })),
                )
                .catch(e => {
                  toast.show('登录失败', { type: 'error' });
                })
                .finally(() => setDataLoading(false));
            } else {
              toast.show('未获取到个人信息');
            }
          })
          .catch(e => {
            toast.show('登录失败', { type: 'error' });
          })
          .finally(() => {
            setDataLoading(false);
          });
      }
    }
  }, []);

  const AlertIcon = props => <Icon {...props} name="alert-circle-outline" />;

  const submit = ({ username, password }) => {
    setDataLoading(true);
    login({ username, password })
      .then(data => {
        setToken({
          ...data,
          expire_time: new Date().valueOf() + data.expires_in,
          scope: undefined,
        });
      })
      .then(() =>
        //   new Promise(resolve =>
        //     fetchAppConfig({  callback: () => resolve(true) }),
        //  ),
        {},
      )
      .catch(e => {
        //toast.show('登录失败', { type: 'error' });
      })
      .finally(() => setDataLoading(false));
  };

  const [secureTextEntry, setSecureTextEntry] = React.useState(true);

  const toggleSecureEntry = () => {
    setSecureTextEntry(!secureTextEntry);
  };

  const renderIcon = props => (
    <TouchableWithoutFeedback onPress={toggleSecureEntry}>
      <Icon {...props} name={secureTextEntry ? 'eye-off' : 'eye'} />
    </TouchableWithoutFeedback>
  );

  const renderCaption = () => {
    return (
      <View style={styles.captionContainer}>
        {AlertIcon(styles.captionIcon)}
        <Text style={styles.captionText}></Text>
      </View>
    );
  };

  return (
    <SafeAreaView style={{ flex: 1 }}>
      {/* <TopNavigation title="MyApp" alignment="center" />
      <Divider /> */}
      <Layout style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        <Formik
          validationSchema={ValidationSchema}
          initialValues={{ username: '', password: initSecret }}
          onSubmit={submit}>
          {({ handleChange, handleBlur, handleSubmit, values, errors }) => (
            <View>
              <Input
                value={values.username}
                label="手机号"
                placeholder="请输入"
                caption={renderCaption}
                onChangeText={handleChange('username')}
                onBlur={handleBlur('username')}
              />

              {/* <Input
                value={values.password}
                label="密码"
                placeholder="请输入"
                caption={renderCaption}
                accessoryRight={renderIcon}
                secureTextEntry={secureTextEntry}
                onChangeText={handleChange('password')}
                onBlur={handleBlur('password')}
              /> */}
              <View style={{ marginTop: 20, alignItems: 'center' }}>
                <Button abpButton onPress={handleSubmit}>
                  <Text style={styles.loginText}>登录</Text>
                </Button>
              </View>
            </View>
          )}
        </Formik>
      </Layout>
      <Loading loading={dataLoading}></Loading>
    </SafeAreaView>
  );
}

LoginScreen.propTypes = {
  // startLoading: PropTypes.func.isRequired,
  // stopLoading: PropTypes.func.isRequired,
  setToken: PropTypes.func.isRequired,
  fetchAppConfig: PropTypes.func.isRequired,
};

export default connectToRedux({
  component: LoginScreen,
  dispatchProps: {
    fetchAppConfig: AppActions.fetchAppConfigAsync,
    setToken: PersistentStorageActions.setToken,
  },
});

const styles = StyleSheet.create({
  captionContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  captionIcon: {
    width: 10,
    height: 10,
    marginRight: 5,
  },
  captionText: {
    fontSize: 12,
    fontWeight: '400',
    fontFamily: 'opensans-regular',
    color: '#8F9BB3',
  },
  loginText: {
    color: 'white',
  },
});
