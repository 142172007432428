import api from './API';

export function postCreatePay({identityNumber,orderId}){
  return api.post(`/api/app/tlFiduciaryPay/CreateFiduciaryPay?orderId=${orderId}&identityNumber=${identityNumber}`).then(({ data }) => data);
}

export function getIndividualByIdentityNumber(params = {}) {
  return api.get('/api/app/individuals/GetByIdentityNumber', { params }).then(({ data }) => data);
}

export function postInsuranceOrder({ orderId }) {
  return api
    .post(`/api/app/tlAcct/InsuranceOrder?orderId=${orderId}`)
    .then(({ data }) => data);
}

export function postCreateInsuranceOrder(body) {
  return api
    .post('/api/app/tlAcct/CreateInsuranceOrder',body)
    .then(({ data }) => data);
}


export function postTLCreate(body) {
    return api
      .post('/api/app/login/tl-create',body)
      .then(({ data }) => data);
  }

//检查该用户是否注册电子账户
export function postCheck({ mobile, name, identityNumber }) {
    return api
      .post(`/api/app/tlAcct/Check?mobile=${mobile}&name=${name}&identityNumber=${identityNumber}`)
      .then(({ data }) => data);
  }

//发送注册短信
export function postSms({ mobile, name, identityNumber }) {
  return api
    .post(`/api/app/tlAcct/SMS?mobile=${mobile}&name=${name}&identityNumber=${identityNumber}`)
    .then(({ data }) => data);
}

//注册
export function postRegister(body) {
  return api.post('/api/app/tlAcct/Register', body).then(({ data }) => data);
}

//申请绑定银行卡
export function postApplyBindCard(body) {
  return api.post('/api/app/tlAcct/ApplyBindCard', body).then(({ data }) => data);
}
//确认绑定银行卡
export function postConfirmBindBankCard(body) {
  return api.post('/api/app/tlAcct/ConfirmBindBankCard', body).then(({ data }) => data);
}
//签订开户协议
export function postSignAgreement(body) {
  return api.post('/api/app/tlAcct/SignAgreement', body).then(({ data }) => data);
}
//开户协议结果
export function postSignAgreementResult(body) {
  return api.post('/api/app/tlAcct/SignAgreementResult', body).then(({ data }) => data);
}
//签订扣款协议
export function postSignAuthAgreement(body) {
  return api.post('/api/app/tlAcct/SignAuthAgreement', body).then(({ data }) => data);
}
//签订扣款协议结果
export function postSignAuthAgreementResult(body) {
  return api.post('/api/app/tlAcct/SignAuthResult', body).then(({ data }) => data);
}

//获取电子账户余额和保单信息
export function getAccountInfo({identityNumber }) {
  return api
    .post(`/api/app/tlAcct/QueryAccountInfo?identityNumber=${identityNumber}`)
    .then(({ data }) => data);
}
