import { NavigationContainer } from '@react-navigation/native';
import i18n from 'i18n-js';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { Platform, StatusBar } from 'react-native';
import { getEnvVars,initSecret } from '../../../Environment';
import AppActions from '../../store/actions/AppActions';
import PersistentStorageActions from '../../store/actions/PersistentStorageActions';
import { createLanguageSelector } from '../../store/selectors/AppSelectors';
import { createTokenSelector } from '../../store/selectors/PersistentStorageSelectors';
import { connectToRedux } from '../../utils/ReduxConnect';
import { isTokenValid } from '../../utils/TokenUtils';
import Toast from 'react-native-fast-toast';
import { DrawerNavigator } from '../../navigators/DrawNavigator';
import AuthNavigator from '../../navigators/AuthNavigator';

import { createStackNavigator } from '@react-navigation/stack';
import HomeScreen from '../../screens/IndexScreen';
import HomeStackNavigator from '../../navigators/HomeNavigator';
import { getProfileDetail } from '../../api/IdentityAPI';
import { postTLCreate } from '../../api/TLCustAPI';
import { login } from '../../api/AccountAPI';

const { localization } = getEnvVars();

i18n.defaultSeparator = '::';

const cloneT = i18n.t;
i18n.t = (key, ...args) => {
  if (key.slice(0, 2) === '::') {
    key = localization.defaultResourceName + key;
  }
  return cloneT(key, ...args);
};
const Stack = createStackNavigator();


function AppContainer({ fetchAppConfig, token, setToken, route }) {
  const isValid = useMemo(() => isTokenValid(token), [token]);

  useEffect(() => {


    if (!isValid && token && token.access_token) {
      setToken({});
    }
  }, [isValid]);

  useEffect(() => {
    fetchAppConfig();
  }, []);

  const linking = {
    prefixes: ['https://payment.insuramp.frameinsure.com', 'https://payment.insuramp.frameinsure.com'],
    config: {
      screens: {
        Login:'Login',
        Home: {
          screens: {
            Index: 'Index',
            SignAgreementResult: 'SignAgreementResult',
            SignAuthResult: 'SignAuthResult',
          },
        },
      },
    },
  };

  return (
    <>
      <NavigationContainer linking={linking}>
        {isValid ? <DrawerNavigator /> : <AuthNavigator />}
        {/* <Stack.Navigator initialRouteName="Home">
        <Stack.Screen
          name="Home"
          component={HomeStackNavigator}
          // options={({ navigation }) => ({
          //   headerLeft: () => <MenuIcon onPress={() => navigation.openDrawer()} />,
          //   title: t('::Menu:Home'),
          // })}
        />
        <Stack.Screen name='Profile' component={ProfileScreen}/>
      </Stack.Navigator> */}
      </NavigationContainer>
      <Toast ref={ref => (global['toast'] = ref)} />
    </>
  );
}

AppContainer.propTypes = {
  token: PropTypes.object.isRequired,
  fetchAppConfig: PropTypes.func.isRequired,
  setToken: PropTypes.func.isRequired,
};

export default connectToRedux({
  component: AppContainer,
  stateProps: state => ({
    token: createTokenSelector()(state),
  }),
  dispatchProps: {
    fetchAppConfig: AppActions.fetchAppConfigAsync,
    setToken: PersistentStorageActions.setToken,
  },
});
